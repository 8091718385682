
export default {
  name: "Footer",
  // props是子组件访问父组件数据的唯一接口
  props: {
    copyright: {
      type: String,
      default: ''
    }
  }
}
