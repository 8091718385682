
    import SearchBox from "@/components/SearchBox";
    import Footer from '@/components/Footer';
    export default {
        name:'Movie',
        components:{
          SearchBox,
          Footer
        }
    }
