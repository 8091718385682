

function search_datasets(query, page, thisobj){
  if(query) {
    thisobj.axios.get('http://180.76.111.208/dataset/api/search?q=' + query+"&page=" + page).then(function (response) {
      thisobj.datasets = response.data.hits;
      if (thisobj.datasets.length > 0){
        thisobj.selected_dataset = thisobj.datasets[0]._source;
      }else{
        thisobj.selected_dataset = null;
      }
      thisobj.total_hits = response.data.total_hits;
      thisobj.took_seconds = response.data.took_seconds;
      thisobj.page = response.data.page;
      thisobj.pages = response.data.pages;
      thisobj.q = response.data.q;
    })
  }
}


export default {
  name: "SearchBox",
  data() {
    return {
      datasets: [],
      total_hits: null,
      took_seconds: null,
      page: null,
      pages: [],
      q: null,
      selected_dataset: null
    }
  },
  methods: {
    search() {
      const query = document.getElementById("s_input").value;
      search_datasets(query, 1, this);
    },
    pagination(query, page){
      search_datasets(query, page, this);
    },
    select_dataset(i){
      this.selected_dataset = this.datasets[i]._source;
    }
  },
  mounted() {
    const query = this.$route.params.q;
    if(query) {
      document.getElementById("s_input").value = query;
      search_datasets(query, 1, this);
    }else{
      this.$router.push({path:'/home'});
    }
  }
}
